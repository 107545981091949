<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2020-11-24 15:47:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="box-create">
        <div class="box-title">{{$t('New_models')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="6">
                    <!-- 区域国家选择 -->
                    <el-form-item :label="$t('Regional_country_selection')" prop="type" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select @change="changeRegionalcountry($event)" v-model="form.type" >
                            <el-option
                                    v-for="item in RegionalcountryArr.AREA_TYPES"
                                    :key="item.id"
                                    :label="item.code"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 区域 -->
                    <el-form-item :label="$t('area_selection')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select filterable @change="getAllCountry" v-model="form.regionID" >
                            <el-option
                                    v-for="item in areaArr"
                                    :key="item.regionID"
                                    :label="item.regionNameInternational"
                                    :value="item.regionID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 国家 -->
                    <el-form-item v-if="RegionalcountryCode==1" :label="$t('nation_selection')" filterable prop="countryID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select filterable v-model="form.countryID" >
                            <el-option
                                    v-for="item in nationArr"
                                    :key="item.countryID"
                                    :label="item.countryNameInternational"
                                    :value="item.countryID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 设备分类 -->
                    <el-form-item :label="$t('Equipment_classification')" filterable prop="invID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select filterable v-model="form.invID" >
                            <el-option
                                    v-for="item in EquipmentArr"
                                    :key="item.invID"
                                    :label="item.name"
                                    :value="item.invID">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <!-- <el-form-item :label="$t('system.country.name')" prop="countryNameInternational" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.countryNameInternational"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('project.data.region')" prop="regionID" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-select v-model="form.regionID" >
                            <el-option
                                    v-for="item in regions"
                                    :key="item.regionID"
                                    :label="item.regionNameInternational"
                                    :value="item.regionID">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                </el-col>
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminrecommend'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{
                type:"",
                regionID:"",
                countryID:"",
                invID:"",
                devType:1,
            },
            id: this.$route.query.id,
            regions:[],
            RegionalcountryArr:{},
            areaArr:[],
            nationArr:[],
            EquipmentArr:[],
            RegionalcountryCode:'',
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.id){
            this.systemTCountryGetById()
        }
        this.getAllRegion()
        this.getAllCountry()
        this.tdictionaryGetDictionaryBatch()
        this.queryModellist()
    },
    methods: {

        /*获取所有区域数据*/
        /*获取所有区域*/
        // async getAllRegion(){
        //     let res = await this.API.systemRegionsGetAll({})
        //     this.regions = res;
        // },


        // /*获取详情*/
        // async systemTCountryGetById() {
        //     let res = await this.API.systemTCountryGetById({
        //         countryID: this.id
        //     })
        //     this.form = res;
        // },


        // 获取字典
        async tdictionaryGetDictionaryBatch() {
            let res = await this.API.tdictionaryGetDictionaryBatch({
                lang: localStorage.getItem("lan"),
                dictCodes: [
                "AREA_TYPES",
                ],
            });
            this.RegionalcountryArr = res;
        },

         /*获取所有区域*/
         async getAllRegion(){
            let res = await this.API.systemRegionsGetAll({})
            this.areaArr = res;
        },

        /*获取所有国家*/
        async getAllCountry(){
            //  console.log(this.form.regionId,'this.form.regionId');
            let res = await this.API.systemCountryGetAll({
                regionId: this.form.regionId
            })
            this.nationArr = res
        },

        // 获取所有的机型列表
        async queryModellist(){
            let res = await this.API.queryModellist()
            this.EquipmentArr = res
        },
        // 改变区域和国家类型的事件
        changeRegionalcountry(value){
            this.RegionalcountryCode = value
        },
        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.id){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*创建*/
        async save(data){
            let res = await this.API.AddNewmodels(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.code==0){
                this.$message.success(this.$t('operate.result.success'));
                this.$router.push({path: '/adminrecommend'});
            }else if(res.code==1){
                this.$message.error(this.$t("Model_repeat"));
            }else if(res.code==500){
                this.$message.error(this.$t("Operation_failed_please_contact_the_administrator"));
            }
        },

        /*更新*/
        async update(data){
            let res = await this.API.systemTCountryUpdate(data)
            this.showMessage(res)

        },

       
    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
}
</style>

